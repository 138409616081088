/**
 * Formats a number to have commas between every 3 numbers, or to use letters to
 * shorten the lower place values (e.g. 343,561 = "343.6K")
 * @param {Number} value The numeric value
 * @param {Boolean} useShorthand True to chop off all but the first numbers before
 *  the comma and adding a K/M/B suffix
 * @returns {String} The formatted value
 */
export default function formatNumericValue(value, useShorthand = false) {
  const formattedValue = (+value || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  if (!useShorthand || !formattedValue.includes(',')) {
    return formattedValue
  }
  const commaCount = formattedValue.split(',').length - 1
  const unit = ['', 'K', 'M', 'B', 'T'][commaCount]
  const firstCommaIdx = formattedValue.indexOf(',')
  const prefix = formattedValue.substr(0, firstCommaIdx)
  const suffixStr = formattedValue.substr(firstCommaIdx + 1, 3)
  const suffixRounded = Math.round(suffixStr / 100)
  const suffix = suffixRounded === 0 ? '' : `.${suffixRounded}`
  return `${prefix}${suffix}${unit}`
}
